import { normalizeCompanyName } from "../../utilities/getNormalizedName";

export const mapUniqueRxToHippo = (RxSenceObj) => {
    return {
        chain: normalizeCompanyName(RxSenceObj.pharmacy.name),
        cost: RxSenceObj.prices.length && RxSenceObj.prices[0].price,
        estRetailPrice: RxSenceObj.prices.length && RxSenceObj.prices[0].usualAndCustomary,
        logo: {
            full: RxSenceObj.pharmacy.logoUrl,
            small: RxSenceObj.pharmacy.logoUrl
        },
        pharmacyName: normalizeCompanyName(RxSenceObj.pharmacy.name),
        rx: RxSenceObj,
        isHippo: false,
        isRx: true,
        isCareCard: false,
        isRxLess: false
    }
}

export const mapUniqueCareCardToHippo = (careCard) => {
    return {
        chain: normalizeCompanyName(careCard?.chain),
        cost: careCard.price && careCard.price,
        estRetailPrice: 0,
        logo: {
            full: "",
            small: ""
        },
        pharmacyName: normalizeCompanyName(careCard.pharmacy.name),
        careCard: careCard,
        isHippo: false,
        isRx: false,
        isRxLess: false,
        isCareCard: true
    }
}

export const mapUniqueRxLessToHippo = (rxLess) => {
    const regex = new RegExp('pharmacy', 'gi');

    return {
        chain: normalizeCompanyName(rxLess.pharmacyName).toLowerCase(),
        cost: rxLess.price && rxLess.price,
        estRetailPrice: 0,
        logo: {
            full: "",
            small: ""
        },
        pharmacyName: normalizeCompanyName(rxLess.pharmacyName),
        rxLess: rxLess,
        isHippo: false,
        isRx: false,
        isCareCard: false,
        isRxLess: true
    }
}

export const mapUniqueHippoToHippo = (HippoObj) => {
    return {
        ...HippoObj,
        pharmacyName: normalizeCompanyName(HippoObj.pharmacyName),
        chain: normalizeCompanyName(HippoObj.pharmacyName),
        isHippo: true,
        isRx: false,
        isCareCard: false,
        isRxLess: false
    }
}

export const mapDuplicatePharmacies = (HippoObj, RxSenceObj) => {
    return {
        ...HippoObj,
        rx: RxSenceObj,
        isHippo: true,
        isRx: true
    }
}