import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../src/assets/css/styles.css";
import Footer from "./components/partials/Footer";
import AutoLogout from "./utilities/AutoLogOut";
import { HashRouter, json } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar'
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";
import { RoutesWithAnimation } from "./AnimatedRoute";
import { IPAPIkey } from "./environment";
import Drawer from "./components/Drawer";
import axios from "axios";
import { httpClientJava } from "./environment";
import { fetchSavingHistory, setSavingsHistory } from "./reducers/medication";
import { setIP } from "./reducers/global";
import { setAuth } from "./reducers/global";
import { useGetUser } from "./utilities/useGetUser";
import { setProgress } from "./reducers/global";



function App() {
  // const [auth, setAuth] = useState("");
  // const [ip, setIp] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [ownPosition, setOwnPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savingsToRender, setSavingToRender] = useState([]);

  const [totalSavings, setTotalSavings] = useState(0);



  const sessionLatitude = window.sessionStorage.getItem("latitude");
  const sessionLongitude = window.sessionStorage.getItem("longitude");
  const sessionPostal = window.sessionStorage.getItem("postalCode");

  const userDetail = useGetUser();

  const savings = useSelector((state) => state.medication.savingHistory);
  const auth = useSelector((state) => state.global.auth);

  const ip = useSelector((state) => state.global.ip);
  const progress = useSelector((state) => state.global.progress);

  const setAuth = (auth) => {
    dispatch(setAuth(auth))
  }


  const dispatch = useDispatch();

  const setIp = (ip) => {
    dispatch(setIP(ip));
  }


  useEffect(() => {
    if (userDetail?.proxyIpAddress)
      setIp(userDetail.proxyIpAddress)
  }, [userDetail?.proxyIpAddress])

  const getZipCode = async () => {
    if (sessionPostal == null) {
      // setLoading(true);
      await httpClientJava.post("/GetIpInfo", {
        ipAddress: ip
      }).then((response) => {
        const res = JSON.parse(response.data.data);
        // setLoading(false);
        setOwnPosition({
          ip: ip,
          lat: res.latitude,
          lng: res.longitude,
          showMap: true,
          postalCode: res.postal,
          responseZIP: res
        });
      }).catch((err) => {
        // setLoading(false)
        console.error(err);
        setOwnPosition({
          ip: ip,
          lat: 33.753746,
          lng: -84.386330,
          showMap: true,
          postalCode: 30301,
          responseZIP: {}
        });
      })
    }
    else {
      setOwnPosition({
        ip: ip,
        lat: sessionLatitude,
        lng: sessionLongitude,
        showMap: true,
        postalCode: sessionPostal,
        responseZIP: {}
      });
    }
  }

  useEffect(() => {
    if (ip != null && ip != "") {
      getZipCode();
    }
  }, [ip])


  // Fallback timeout to hide the loading bar after 1 minute
  useEffect(() => {
    if (progress > 0 && progress < 100) {
      // Set a fallback timeout to complete the loading bar after 1 minute
      const timeoutId = setTimeout(() => {
        dispatch(setProgress(100));  // Reset the progress in Redux store
      }, 30000); // 30 seconds fallback

      return () => clearTimeout(timeoutId); // Cleanup the timeout
    }
  }, [progress]);

  useEffect(() => {

    if (!savings || savings.status !== "OK" || !savings.data || savings.data.length === 0) {
      setSavingToRender([])
      return;
    }

    const filledSavings = savings.data.filter(item => item.claim_Status === "filled");

    if (filledSavings.length === 0) {
      setSavingToRender([])
      return;
    }

    const savingsData = filledSavings.reduce((accumulator, saving, index) => {
      const existingIndex = accumulator.findIndex(el => el.dateFilled === saving.date_filled);

      if (existingIndex !== -1) {
        // Update existing entry.
        accumulator[existingIndex].drugsName += `, ${saving.drugName}`;
        accumulator[existingIndex].price += saving.copay_amount;
      } else {
        // Create a new entry.
        accumulator.push({
          id: index,
          drugsName: saving.drugName,
          dateFilled: saving.date_filled,
          price: saving.copay_amount,
        });
      }

      return accumulator;
    }, []);

    setSavingToRender(savingsData);
  }, [savings]);

  useEffect(() => {
    if (savingsToRender.length > 0 && totalSavings == 0) {

      savingsToRender.map((el, i) => {
        setTotalSavings(prev => prev + el.price)
      })
    }
    if (savingsToRender.length == 0) {
      setTotalSavings(0)
    }
  }, [savingsToRender])

  return (
    <>
      {loading ? <div className="loader"></div> : <div className="appContainer">
        <LoadingBar
          color='#22A699'
          progress={progress}
          height={4}
        // onLoaderFinished={() => dispatch(setProgress(0))}
        />
        <div className="heading">
        </div>
        <div className="">
          <HashRouter>
            <ToastContainer />
            <AutoLogout>
              <LocationProvider>
                <Drawer totalSavings={totalSavings} />
                <RoutesWithAnimation ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />
              </LocationProvider>
            </AutoLogout>
          </HashRouter>
        </div>
        <Footer />
      </div>}
    </>
  );
}
export default App;

const LocationProvider = ({ children }) => {
  return <AnimatePresence >{children}</AnimatePresence>;
}
