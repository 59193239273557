// import placeholderLogo from "../../assets/images/RxLink assets/login-logo.png"
import { useEffect, useState, useRef } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../reducers/auth";
import { resetPassword } from "../../reducers/resetPassword";
import { forgetPwd } from "../../reducers/forgotPassword";
import ResetPassword from "./ResetPassword";
import ForgetPassword from "./ForgetPassword";
import { fetchEnrollmentList, setViewPatient } from "../../reducers/enrollmentList";
import ForgotTempPassword from "./forgotTempPassword";
import { domainName } from "../../environment";
import { successToast, errorToast } from "../toast/toast"
import "react-toastify/dist/ReactToastify.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { loginRouteVariants } from "../animationsVariants";
import { MotionText } from "../motion/MotionText";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  validateOTP,
  generateOtp,
  otpExpiredApi,
} from "../../reducers/validateOtp";
import { setShowPahrmacyMap, setShowResetPasswordModal } from "../../reducers/medication";
import { UsernameField, usernameValidation } from "../Fields/Username";
import { setIP, setAuth } from "../../reducers/global";
import { toast } from "react-toastify";
import { PasswordRegex } from "../../utilities/constants";
import { getDomainName } from "../../utilities/useGetDomain";
import { useGetUser } from "../../utilities/useGetUser";
import logo from "../../assets/images/logo.png"; // for Rxlink


export default () => {
  const userDetail = useGetUser();
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);
  const [isOpenForgotPwdModal, setIsOpenForgotPwdModal] = useState(false);
  const [resetPwd, setResetPasswd] = useState("");
  const [isTempReset, setIsTempReset] = useState(false);
  const [submitResetPasswordData, setSubmitResetPasswordData] = useState("");
  const [submitForgotPasswordData, setSubmitForgotPasswordData] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [validateEmail, setValidateEmail] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var numberPattern = /\d+/g;

  const isNewUser = window.location.href.includes("?p=") ? true : false;
  const hasDob = window.location.href.includes("hasDob=1") && isNewUser ? true : window.location.href.includes("hasDob=0") && isNewUser ? false : false;
  const hasFirstName = isNewUser && window.location.href.includes("hasDob") ? true : false;

  const { domainName, defaultLogo } = getDomainName();

  const getParamFromURL = (paramName) => {
    const url = window.location.href;
    const paramStart = url.indexOf(`?${paramName}=`);
    if (paramStart === -1) {
      return null; // Parameter not found in the URL
    }

    const paramEnd = url.indexOf('&', paramStart);
    const valueStart = paramStart + paramName.length + 2; // Add 2 for the "=" character

    if (paramEnd === -1) {
      return url.substring(valueStart);
    } else {
      return url.substring(valueStart, paramEnd);
    }
  }

  var patientHash = getParamFromURL("p");



  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email is required")
      .email("Invalid email"),

    password: Yup.string()
      .required("Password is required")
    // .min(8, "Password must be at least 8 characters")
    // .max(40, "Password must not exceed 40 characters")
    // .matches(
    //   PasswordRegex,
    //   'Password must be 8 characters long and contain a mix of letters and numbers.'
    // ),
  });

  const validationSchemaNewUser = hasDob ? Yup.object().shape({
    dob: Yup.string().required("Required"),
  }) : hasFirstName ? Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required")
  }) : null


  //   const Character = styled(motion.span)`
  //   display: inline-block;
  //   margin-right: -0.05em;
  // `;

  const showResetPasswordModal = useSelector((state) => state.medication.showResetPasswordModal);
  const pickerRef = useRef(null)



  const queryParameters = new URLSearchParams(window.location.search);
  const isUpenn = window.location.href.includes("upenn");
  const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
  const isTripment = window.location.href.includes("tripment");
  const isHouston = window.location.href.includes("hhc");
  const isLcmc = window.location.href.includes("lcmc");




  // console.log({ isNewUser, hasDob, hasFirstName })
  const [isReadOnly, setIsReadOnly] = useState(true);


  useEffect(() => {
    if (pickerRef.current !== null)
      pickerRef.current.input.readOnly = isReadOnly;
  }, [isReadOnly])


  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      "username": "",
      "password": "",
      "domainName": domainName

    },
    validationSchema: validationSchema,
    onSubmit: (data, { resetForm, setFieldValue }) => {
      setLoading(true);

      dispatch(login(data)).unwrap().then((res) => {
        setValidateEmail(true);
        localStorage.setItem("user", JSON.stringify(res?.data));
        if (res?.data) {
          setLoading(false);

          if (res?.data?.enrollmentStatus === 'Unverified') {
            dispatch(generateOtp(data)).unwrap().then((result) => {

              if (result?.data) {
                localStorage.setItem("user", JSON.stringify(res?.data));
                successToast(result?.message);
                navigate("/otpVerify");
                dispatch(setIP(res?.data.proxyIpAddress));
              }
            })

          }
          else {

            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              dispatch(setAuth(res?.data));
              setLoading(false);
              navigate('/patient');
              dispatch(setIP(res?.data.proxyIpAddress));
            }
            else if (res?.statusCode == 307) {
              errorToast(res?.message);
              setLoading(false);
            }
          }

        }
        else {
          errorToast(res?.message);
          setLoading(false);

        }
      })

    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  };

  useEffect(() => {
    if (submitForgotPasswordData?.number) {
      setLoading(true);
      dispatch(
        forgetPwd(
          submitForgotPasswordData?.number
        )
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify(response?.data));
            successToast(response?.message);
            setIsOpenForgotPwdModal(false);
            setIsTempReset(true);
          } else {
            errorToast(response?.message);
            setLoading(false);
          }
        });
    }
  }, [submitForgotPasswordData]);

  useEffect(() => {
    if (submitResetPasswordData?.password) {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      setResetPasswd(body);
    }
  }, [submitResetPasswordData]);


  useEffect(() => {
    if (resetPwd?.password) {
      setLoading(true);
      dispatch(resetPassword(resetPwd))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.statusCode >= 200 && response?.statusCode <= 299) {
            // setIsOpenResetPwdModal(false);
            dispatch(setShowResetPasswordModal(false));
            successToast(response?.message);
            setIsTempReset(false);
            userDetail = JSON.parse(localStorage.getItem("user"));
            window.location.replace("/#/patient")
          } else {
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [resetPwd]);

  const signUp = (e) => {
    navigate("/signUp");
  };

  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const text = 'Personalized medication savings';

  // console.log({ isNewUser })




  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <motion.section className=""
            // variants={loginRouteVariants}
            // initial="initial"
            // animate="final"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="login_section">
              <motion.form onSubmit={(e) => {
                setValidateEmail(true)
                formik.handleSubmit(e)
              }
              } id="loginID"
              >
                <div className="row g-0">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="login_section_inner">
                      <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="logo">
                            <motion.img src={defaultLogo} className="img-fluid"
                              initial={{ y: -100 }}
                              animate={{ y: 0 }}
                              transition={{ duration: 0.5, delay: 0.4 }}
                            />
                          </div>
                          <MotionText text={text}
                            ctrls={ctrls}
                            wordAnimation={wordAnimation}
                            characterAnimation={characterAnimation}
                            cref={ref}
                          />
                        </div>
                      </div>
                      <UsernameField
                        formik={formik}
                        validateSubmit={validateEmail}
                      />
                      <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="forget_input_eye mb-1  form-group">
                            <input
                              name="password"
                              type={passwordType}
                              className={
                                "form-control custom-form-control" +
                                (formik.errors.password &&
                                  formik.touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              id="floatingInput"
                              placeholder="Password"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                            />
                            <div className="invalid-feedback">
                              {formik.errors.password && formik.touched.password
                                ? formik.errors.password
                                : null}
                            </div>
                            <i
                              className={passwordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                              onClick={() => changePasswordType()}
                              style={{ top: 13, color: "#77aca2" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0">
                        <motion.div className="col-sm-12 col-md-12 col-lg-12"
                          initial={{ y: 50 }}
                          transition={{ type: "spring", duration: 0.8 }}
                          animate={{ y: 0 }}
                          whileTap={{
                            scale: 0.95,
                            transition: { duration: 0.1 },
                          }}                        >
                          <button
                            className="btn_success"
                            type="submit"
                            form="loginID"
                          >
                            Submit
                          </button>
                        </motion.div>
                      </div>
                      {!isNewUser && <div className="row g-0">
                        <motion.div className="col-sm-12 col-md-12 col-lg-12"
                          initial={{ y: 50 }}
                          transition={{ type: "spring", duration: 0.8 }}
                          animate={{ y: 0 }}
                          whileTap={{
                            scale: 0.95,
                            transition: { duration: 0.1 },
                          }}
                        >
                          {/* <button
                            className="btn_default"
                            onClick={() => signUp()}
                            form="loginID"
                          >
                            Sign Up
                          </button> */}
                        </motion.div>
                      </div>}
                      {/* <div className="row g-0">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p
                            className="password pointer"
                            onClick={() => signUp()}
                          >
                            <a>Don’t have a password? Click here to sign Up</a>
                          </p>
                        </div>
                      </div> */}
                      <div className="row g-0 mt-1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p
                            className="password pointer m-0"
                            onClick={() => setIsOpenForgotPwdModal(true)}
                          >
                            <a>I don't have a password</a>
                          </p>

                        </div>
                      </div>
                      {domainName == "HCACare" &&
                        <div style={{ marginLeft: "auto", marginRight: "auto", left: 0, right: 0 }}>
                          <p
                            className="password pointer m-0"
                            style={{ paddingTop: 10 }}
                          >
                            <span
                              className="txtDecoration"
                              style={{ fontSize: 13 }}
                            >powered by
                              <img
                                src={logo}
                                className="img-fluid"
                                style={{ maxWidth: 68, marginLeft: 2 }}
                              /></span>
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </motion.form>
            </div>
          </motion.section>

          <ForgetPassword
            show={isOpenForgotPwdModal}
            close={() => setIsOpenForgotPwdModal(false)}
            setSubmitForgotPasswordData={setSubmitForgotPasswordData}
          />

          <ResetPassword
            show={showResetPasswordModal}
            close={() => {
              // if(isOpenResetPwdModal)
              // setResetPwd(false);
              dispatch(setShowResetPasswordModal(false));
            }}
            setSubmitResetPasswordData={setSubmitResetPasswordData}
          />

          <ForgotTempPassword
            show={isTempReset}
            close={() => setIsTempReset(false)}
            setSubmitResetPasswordData={setSubmitResetPasswordData}
          />
        </>
      )}
    </>
  );
};
