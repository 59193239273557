
import { logoPharmacies } from "../components/PharmacyLogo";

// Define a mapping of variations to their normalized names

export const companyMap = {
    "WALGREENS": "WALGREENS",
    "WALGREENS PHARMACY": "WALGREENS",
    "WALGREEN PHARMACY": "WALGREENS",
    "WALGREENS CO": "WALGREENS",
    "WALGREEN CO": "WALGREENS",
    "WALGREEN": "WALGREENS",
    "WAL-MART": "WALMART",
    "WALMART": "WALMART",
    "WALMART PHARMACY": "WALMART",
    "CVS PHARMACY": "CVS",
    "CVS INSIDE TARGET": "CVS",
    "GEORGIA CVS PHARMACY, L.L.C.": "CVS",
    "GEORGIA CVS PHARMACY L.L.C.": "CVS",
    "GEORGIA CVS PHARMACY LLC": "CVS",
    "KROGER PHARMACY": "KROGER",
    "H-E-B": "HEB",
    "H-E-B PHARMACY": "HEB",
    "HEB PHARMACY": "HEB",
    "COSTCO PHARMACY": "COSTCO",
    "ALBERTSON PHARMACY": "ALBERTSON",
    "HARRIS DRUG STORE": "HARRIS DRUG",
    "SAFEWAY PHARMACY": "SAFEWAY",
    "RITE AID PHARMACY": "RITE AID",
    "RITE_AID": "RITE AID",
    "RITE_AID PHARMACY": "RITE AID",
    "PUBLIX PHARMACY": "PUBLIX",
    "WALMART NEIGHBORHOOD MARKET": "WALMART",
    "WASHINGTON CVS PHARMACY LLC": "CVS",
    "GARFIELD BEACH CVS LLC": "CVS"
    // Add more mappings as needed
};

export const normalizeCompanyName = (companyName) => {
    if (!companyName) return
  
    // Convert input to uppercase for case-insensitive matching
    let normalized = companyName.toUpperCase();

    // Check if the normalized name exists in our map, return the mapped value if found
    if (companyMap.hasOwnProperty(normalized)) {
        return companyMap[normalized]?.toLowerCase();
    }

    // **New Feature: Check if any mapped value is included in the companyName**
    for (const [key, value] of Object.entries(companyMap)) {
        if (normalized?.includes(value)) {
            return value?.toLowerCase();
        }
    }

    // else check the logoPharmacies list
    logoPharmacies?.map((pharmacy)=>{
        if (normalized?.includes(pharmacy?.pharmacy?.toUpperCase())) {
            return pharmacy?.pharmacy?.toLowerCase();
        }
    })

    return companyName?.toLowerCase();
}
