import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion, AnimatePresence, color } from "framer-motion";
import { MotionButton } from "../motion/MotionButton";
import tabHealth from "../../assets/images/tab-health.svg";
import { genderOptions } from "./content";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
// import Vector from "../../assets/images/tab-otp.svg";
import Vector from "../../assets/images/provider-onboarding2.png";
import { useDispatch } from "react-redux";
import { addOrUpdatePatient } from "../../reducers/patient";
import { successToast } from "../toast/toast";
import DateOfBirth from "../../utilities/DateOfBirth";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import { setFromSavings } from "../../reducers/medication";
import { useGetUser } from "../../utilities/useGetUser";
import { setPatient } from "../../reducers/global";
import { getDomainName } from "../../utilities/useGetDomain";


const Patient = () => {
    const pickerRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const user = useGetUser();
    // useEffect(() => {
    //     pickerRef.current.input.readOnly = true;
    // }, [pickerRef])

    const { windowWidth } = useWindowWidth()

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Required").matches(/^\S*$/, "No spaces allowed"),
        middleName: Yup.string(),
        lastName: Yup.string().required("Required").matches(/^\S*$/, "No spaces allowed"),
        gender: Yup.string().required("Required"),
        dob: Yup.string().required("Required"),
        bin: Yup.string(),
        pcn: Yup.string(),
        groupNumber: Yup.string(),
        memberId: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            middleName: "",
            lastName: "",
            dob: "",
            gender: "",
            bin: "",
            pcn: "",
            groupNumber: "",
            memberId: "",
        },
        validationSchema,
        onSubmit: (data) => {
            if (loading) return;
            let body = {
                firstName: data?.firstName,
                middleName: data?.middleName,
                lastName: data?.lastName,
                dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
                gender: data?.gender,
                bin: data?.bin,
                pcn: data?.pcn,
                groupNumber: data?.groupNumber,
                memberId: data?.memberId,
                appUserSeqNo: user?.appUserSeqNo,
                pid: "Clinical"
            };
            setLoading(true);
            try {
                dispatch(addOrUpdatePatient(body)).unwrap().then((res) => {
                    if (res?.data) {
                        successToast(res?.message);
                        localStorage.setItem("patientSeqNo", res?.data?.patientSeqNo);
                        dispatch(setPatient(res?.data));
                        dispatch(setFromSavings(false));
                        setLoading(false);
                        navigate('/medications');
                    }
                });
            } catch (e) {
                setLoading(false);
            }
        },
    });
    const getSectionClass = windowWidth > 991 ? "fadeAni cont-style" : "fadeAni";

    const imageStyle = windowWidth > 991 ? { marginRight: "10vw" } : { marginRight: "36vw" }

    return (
        <>
            <AnimatePresence mode="wait">
                <section className={getSectionClass} >
                    <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12" style={{ width: "94%", alignContent: 'end' }}>
                            <div className="medicine_icon" style={{ margin: windowWidth > 991 ? "25px 0px 25px 0px" : "25px 0px 0px 0px" }}>
                                <motion.img
                                    whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                                    src={avatar}
                                    className="img-fluid pointer"
                                    id="dropdownMenuButton1"
                                    aria-expanded="false"
                                    type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                ></motion.img>
                            </div>
                        </div>
                    </div>
                    {/* {windowWidth < 991 && <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="">&nbsp;</div>
                        </div>
                    </div>} */}
                    <div  >
                        <div className="row g-0">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 p-0 autoMargin">
                                <div className="vector-image" style={imageStyle}>
                                    <motion.img
                                        key={Vector}
                                        // src={image.src}
                                        initial={{ opacity: 0, x: 500 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0 }}
                                        src={Vector}
                                        transition={{ duration: 0.8, type: "tween" }}
                                        className="img-fluid"
                                    />
                                    {/* </AnimatePresence> */}
                                </div>
                            </div>
                            <div
                                className="col-12 col-sm-12 col-md-12 col-lg-7 paddingZero autoMargin"
                                style={{ zIndex: 100 }}
                            >
                                <div className="">

                                    <motion.div
                                        className="insurance_inner_main insurance-padding"
                                        initial={{ scale: 0.8 }}
                                        animate={{ scale: 1 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <form onSubmit={formik.handleSubmit} id="loginID">
                                            <div className="row" id="cc-info-div">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <motion.div
                                                        className="insurance_inner"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        transition={{ duration: 0.7 }}
                                                    >
                                                        <div className="row webView">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                {
                                                                    <div className="page_counter" style={{ marginTop: -30, paddingBottom: 20 }}>
                                                                        <h4 style={{ fontSize: 14 }}>1</h4>
                                                                        <motion.h3
                                                                            className="active"
                                                                            initial={{ scale: 0.5 }}
                                                                            animate={{ scale: 1 }}
                                                                            transition={{ duration: 1 }}
                                                                        >
                                                                            2
                                                                        </motion.h3>
                                                                        <h4 style={{ fontSize: 14 }}>3</h4>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <motion.div
                                                            className="row"
                                                            initial={{ x: 300 }}
                                                            animate={{ x: 0 }}
                                                            transition={{ duration: 0.8 }}
                                                        >
                                                            <div className={`col-12 col-sm-12 col-md-12 col-lg-12`}>
                                                                <h1>Enter new patient</h1>
                                                            </div>
                                                        </motion.div>
                                                        <motion.div
                                                            className="row"
                                                            initial={{ x: 300 }}
                                                            animate={{ x: 0 }}
                                                            transition={{ duration: 0.8, delay: 0.15 }}
                                                        >
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <p>
                                                                    Although not required, adding your patient's insurance
                                                                    guarantees we can present the best medication
                                                                    savings options
                                                                </p>
                                                            </div>
                                                        </motion.div>
                                                        <motion.div
                                                            className="row g-1 mb-1"
                                                            initial={{ x: 300, opacity: 0 }}
                                                            animate={{ x: 0, opacity: 1 }}
                                                            transition={{ duration: 0.8, delay: 0.25 }}
                                                            exit={{ x: 300 }}
                                                        >
                                                            <p style={{ fontSize: 11, fontWeight: "bold" }}>PERSONAL INFORMATION</p>
                                                            <div className="col-sm-12 col-md-4 col-lg-4 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="firstName"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.firstName && formik.touched.firstName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="First Name*"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.firstName}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.firstName && formik.touched.firstName
                                                                        ? formik.errors.firstName
                                                                        : null}{" "}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-4 col-lg-4 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="middleName"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.middleName && formik.touched.middleName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Middle Name"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.middleName}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.middleName && formik.touched.middleName
                                                                        ? formik.errors.middleName
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-4 col-lg-4 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.lastName &&
                                                                            formik.touched.lastName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Last Name*"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.lastName}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.lastName &&
                                                                        formik.touched.lastName
                                                                        ? formik.errors.lastName
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                        <motion.div
                                                            className="row g-1"
                                                            initial={{ x: 300, opacity: 0 }}
                                                            animate={{ x: 0, opacity: 1 }}
                                                            transition={{ duration: 0.8, delay: 0.25 }}
                                                            exit={{ x: 300 }}
                                                        >
                                                            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                                                                <select
                                                                    name="gender"
                                                                    style={{ color: formik.values.gender === "" && "rgba(0, 0, 0, 0.35)" }}
                                                                    defaultValue=""
                                                                    className={
                                                                        "form-select  custom-form-control custom-form-select" +
                                                                        (formik.errors.gender && formik.touched.gender
                                                                            ? " is-invalid form-select-is-invalid"
                                                                            : "")
                                                                    }
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.gender}
                                                                >
                                                                    <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                                                    {genderOptions.map(option => (
                                                                        <option key={option.value} value={option.value} style={{ color: "black" }}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.gender && formik.touched.gender
                                                                        ? formik.errors.gender
                                                                        : null}{" "}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                                                                <div className="date-picker-container">
                                                                    {/* <DatePicker
                                                                        ref={pickerRef}
                                                                        name="dob"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        maxDate={new Date()}
                                                                        className={
                                                                            "form-control  custom-form-control" +
                                                                            (formik.errors.dob && formik.touched.dob
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                        onChange={date => {
                                                                            formik.setFieldValue('dob', date)
                                                                        }}
                                                                        selected={formik.values.dob}
                                                                        placeholderText="Date of Birth*"
                                                                    /> */}

                                                                    <DateOfBirth formik={formik} fieldName={'dob'} enabled={true} />

                                                                </div>
                                                                {/* <div className="date-picker-feedback invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.dob && formik.touched.dob
                                                                        ? formik.errors.dob
                                                                        : null}{" "}
                                                                </div> */}
                                                            </div>
                                                        </motion.div>
                                                        <div className="mt-2">
                                                            <div className="hrDiv hrDivpadding" style={{ width: "100%" }}></div>
                                                        </div>
                                                        <motion.div
                                                            className="row g-1"
                                                            initial={{ x: 300, opacity: 0 }}
                                                            animate={{ x: 0, opacity: 1 }}
                                                            transition={{ duration: 0.8, delay: 0.25 }}
                                                            exit={{ x: 300 }}
                                                        >
                                                            <p style={{ fontSize: 11, fontWeight: "bold", marginTop: 0 }}>INSURANCE</p>
                                                            <div className="col-sm-12 col-md-3 col-lg-3 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="bin"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.bin && formik.touched.bin
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="BIN"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.bin}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.bin && formik.touched.bin
                                                                        ? formik.errors.bin
                                                                        : null}{" "}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-3 col-lg-3 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="pcn"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.pcn && formik.touched.pcn
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="PCN"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.pcn}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.pcn && formik.touched.pcn
                                                                        ? formik.errors.pcn
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-3 col-lg-3 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="groupNumber"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.groupNumber &&
                                                                            formik.touched.groupNumber
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Group"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.groupNumber}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.groupNumber &&
                                                                        formik.touched.groupNumber
                                                                        ? formik.errors.groupNumber
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-3 col-lg-3 form-group">
                                                                <input
                                                                    type="text"
                                                                    name="memberId"
                                                                    className={
                                                                        "form-control  custom-form-control" +
                                                                        (formik.errors.memberId &&
                                                                            formik.touched.memberId
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                    placeholder="Member ID"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.memberId}
                                                                />
                                                                <div className="invalid-feedback">
                                                                    {" "}
                                                                    {formik.errors.memberId &&
                                                                        formik.touched.memberId
                                                                        ? formik.errors.memberId
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                        {/* <motion.div
                                  className="button_div insurance_inner_main_btn mt-4 g-0"
                                  id="cc-info-div-smbt"
                                  initial={{ x: 300 }}
                                  animate={{ x: 0 }}
                                  transition={{ duration: 0.7 }}
                                >
                                  <div className="margin-right">
                                    <MotionButton>
                                      <button
                                        className="btn_success"
                                        type="submit"
                                        onClick={() => submit()}
                                      >
                                        Submit
                                      </button>
                                    </MotionButton>
                                  </div>
    
                                  <div className="margin-right webView">
                                    <MotionButton>
                                      <button
                                        className="btn_default NIbutton "
                                        type="button"
                                        onClick={() => CopayProgram()}
                                      >
                                        No&nbsp;insurance
                                      </button>
                                    </MotionButton>
                                  </div>
                                  <div className="margin-right webView">
                                    <MotionButton>
                                      <button
                                        type="button"
                                        className="btn_default NIbutton"
                                        onClick={() => CopayProgram()}
                                      >
                                        Skip
                                      </button>
                                    </MotionButton>
                                  </div>
                                  
                                  <div className="row mobileView">
                                    <div className="col-6">
                                      <MotionButton>
                                        <button
                                          className="btn_default "
                                          type="button"
                                          onClick={() => CopayProgram()}
                                        >
                                          No&nbsp;insurance
                                        </button>
                                      </MotionButton>
                                    </div>
                                    <div className="col-6">
                                      <MotionButton>
                                        <button
                                          type="button"
                                          className="btn_default "
                                          onClick={() => CopayProgram()}
                                        >
                                          Skip
                                        </button>
                                      </MotionButton>
                                    </div>
                                  </div>
                                </motion.div> */}
                                                    </motion.div>
                                                    <motion.div
                                                        className="button_div insurance_inner_main_btn2  g-0"
                                                        id="cc-info-div-smbt"
                                                        initial={{ x: 300 }}
                                                        animate={{ x: 0 }}
                                                        transition={{ duration: 0.7 }}
                                                    >
                                                        <div className="margin-right">
                                                            <MotionButton>
                                                                <button
                                                                    className="btn_success"
                                                                    type="submit"
                                                                >
                                                                    Confirm
                                                                </button>
                                                            </MotionButton>
                                                        </div>

                                                        {/* <div className="margin-right">
                                                            <MotionButton>
                                                                <button
                                                                    className="btn_default"
                                                                    type="button"
                                                                    onClick={() => navigate('/medications')}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </MotionButton>
                                                        </div> */}

                                                        {/* for mobile View */}
                                                        {/* <div className="row mobileView">
                                                            <div className="col-6">
                                                                <MotionButton>
                                                                    <button
                                                                        className="btn_default "
                                                                        type="button"
                                                                    >
                                                                        Confirm
                                                                    </button>
                                                                </MotionButton>
                                                            </div>

                                                        </div> */}
                                                    </motion.div>
                                                </div>
                                            </div>
                                        </form>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobileView">
                        <div className="row g-0">
                            <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
                                <div className="dot-main">
                                    <span className="dot dotActive"></span>
                                    <span className="dot"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </AnimatePresence>
        </>
    );
};

export default Patient;