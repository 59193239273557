export const customMedications = [
    {
        name: "Fasenra",
        form: "Prefilled Syringe",
        dosage: "1 ml",
        quantity: "1",
        default_quantity: "1",
        seoName: "Fasenra",
        ndc: "00310173030",
        package_size: 1,
        search_name: "Fasenra",
        discription: "",
        quantities: ["1"],
        type: "Brand",
        names: ["Fasenra"],
    },
    {
        name: "Xyrem",
        form: "Bottle",
        dosage: "180 mL",
        quantity: "1",
        default_quantity: "1",
        seoName: "Xyrem",
        ndc: "00054962857",
        package_size: 1,
        search_name: "Xyrem",
        discription: "",
        quantities: ["1"],
        type: "Brand",
        names: ["Xyrem"],
    },
    {
        name: "Vyndamax",
        form: "Capsules",
        dosage: "61 mg",
        quantity: "10",
        default_quantity: "10",
        seoName: "Vyndamax",
        ndc: "00069873030",
        package_size: 1,
        search_name: "Vyndamax",
        discription: "",
        quantities: ["10"],
        type: "Brand",
        names: ["Vyndamax"],
    },
    {
        name: "Vyndaqel",
        form: "Capsules",
        dosage: "20 mg",
        quantity: "10",
        default_quantity: "10",
        seoName: "Vyndaqel",
        ndc: "00069197512",
        package_size: 1,
        search_name: "Vyndaqel",
        discription: "",
        quantities: ["10"],
        type: "Brand",
        names: ["Vyndaqel"],
    }
]