import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClient, httpClientRxSense, httpClientJava, httpClientJavaRxSense } from "../environment";

const initialState = {
    drugPrices: [],
    rxSenseDrugPrices: [],
    extendedDrugPrices: [],
  };
  
export const fetchDrugsPrices = createAsyncThunk("DrugDetails", (url) =>
    httpClient
    .get(url) 
    .then(({data}) => {
        return data;
        // const dispatch = useDispatch();
        // dispatch(setDrugsList(data));
    })
    .catch(error => error)
);

// export const fetchRxSenseDrugPrices = createAsyncThunk("RxSenseDrugDetails", ( body) =>{
//   // debugger;
//   httpClientJavaRxSense
//     .post("rxSense/GetTieredPricing", body) 
//     .then(({data}) => {
//       debugger;
//         return data;
//         // const dispatch = useDispatch();
//         // dispatch(setDrugsList(data));
//     })
//     .catch(error => error)
//   });
export const fetchRxSenseDrugPrices = createAsyncThunk("RxSenseDrugDetails", async (body) => {
  try {
    const response = await httpClientJavaRxSense.post("rxSense/GetTieredPricing", body);
    return response.data;
  } catch (error) {
    throw error; // Rethrow the error to be handled by Redux Toolkit
  }
});


export const fetchUNADrugPrices = createAsyncThunk("RxSenseDrugDetails", async (body) => {
  try {
    const response = await httpClientJavaRxSense.post("rxSense/getUnaPricesInfo", body);
    return response.data;
  } catch (error) {
    throw error; // Rethrow the error to be handled by Redux Toolkit
  }
});


export const drugdetails = createSlice({
    name: "drugdetails",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setDrugDetails: (state, action) => {
        state.drugPrices = action.payload;
      },
      setExtendedDrugDetails: (state, action) => {
        state.extendedDrugPrices = action.payload;
      },
      setRxSenseDrugDetails: (state, action) => {
        state.rxSenseDrugPrices = action.payload;
      },
        [fetchDrugsPrices.pending.type]: (state, action) => {
          state.drugs = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [fetchDrugsPrices.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.drugs = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [fetchDrugsPrices.rejected.type]: (state, action) => {
          state.drugs = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setDrugDetails, setRxSenseDrugDetails, setExtendedDrugDetails } = drugdetails.actions;

export default drugdetails.reducer;