
import React, { useState, useEffect } from 'react';

const LogoComponent = ({ drug }) => {
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if(drug){
      let attempt = 4;
      const tryLoadImage = async (name , type) => {
        const imageUrl = `https://images.rxlink.com/connectiveRxlogos/${name}.${type}`;
        try {
          await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img.src);
            img.onerror = () => reject();
            img.src = imageUrl;
          });
          setImageUrl(imageUrl);
        } catch (error) {
          if(attempt == 4){
            tryLoadImage(drug?.search_name?.toLowerCase(),'svg');
          }
          if(attempt == 3){
            tryLoadImage(drug?.search_name?.toLowerCase(),'jpg');
          }
          if(attempt == 2){
            tryLoadImage(drug?.name?.toLowerCase(),'png');
          }
          if(attempt == 2){
            tryLoadImage(drug?.name?.toLowerCase(),'svg');
          }
          if(attempt == 0){
            tryLoadImage(drug?.name?.toLowerCase(),'jpg');
          }
          attempt--;
          // Failed to load image
        }
      };
  
      tryLoadImage(drug?.search_name?.toLowerCase(),'png');
    }
    
    // tryLoadImage('svg');
    // tryLoadImage('jpg');
  }, [drug]);

  if (!imageUrl) {
    console.error(`Logo not found for ${drug?.name?.toLowerCase()}`);
    return <div>{drug?.name?.toLowerCase()}</div>;
  }

  return (
    <img
      src={imageUrl}
      alt={drug?.name}
      style={{ maxWidth: 90, maxHeight: 36 }}
      title={drug?.name}
    />
  );
};

export default LogoComponent;