import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { addOrUpdateMedication } from "../../reducers/saveOrUpdateMedication";
import { setDrugDetails } from "../../reducers/drugPrices";
import Tablet from "../../assets/images/RxLink assets/new assets/med type/tablet.svg";
import Capsule from "../../assets/images/RxLink assets/new assets/med type/capsule.svg";
import trash from "../../assets/images/RxLink assets/new assets/Trash_light.svg";
import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useStyleMediaQuery } from "../coupan/hooks";
import {
  motion,
  AnimatePresence,
} from "framer-motion";
import { MotionButton } from "../motion/MotionButton";
import CPDImg from "../../assets/images/CPD_logo.jpg";
import Tooltip from 'react-bootstrap/Tooltip';
import { Popover } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { baseUrlRxSense, httpClientJavaRxSense, httpClientConnective } from "../../environment";
import { searchPharmacyLogo } from "../PharmacyLogo";
import Liquid from "../../assets/images/liquid.png";
import { isMobile } from "react-device-detect";
import LogoComponent from "../LogoComponent";
import { useGetUser } from "../../utilities/useGetUser";
import EditMedicationModal from "./EditMedicationModal";
import { StringToHTML } from "../../utilities/StringToHtml";
import InfoSvg from "./../../assets/images/info.svg";
import NewTabRedirector from "../../utilities/NewTabRedirector";

var numberPattern = /^-?\d*(\.\d{0,2})?$/g;
const InitObject = {
  variant_id: 0,
  quantity: 0,
  package_size: 0,
  drugPrices: [],
};

export const getCrxBestPrice = (sub_element) => {
  let priceToAdd = 0;
  let priceToMinus = 0;
  sub_element?.drugs?.map((dr) => {
    if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
      priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
      priceToMinus += dr?.bestPrice;
    }
  })
  let newSum = sub_element?.bestPrice - priceToMinus + priceToAdd;
  return newSum?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
}

export default ({
  deleteShowParam,
  medicationInfo,
  setMedicationInfo,
  setAddOrUpdateMed,
  quantity,
  setQuantity,
  label,
  setLabel,
  form,
  setForm,
  dosage,
  setDosage,
  setRemoveDrug,
  bestPackage,
  patient,
  ownPosition,
  userPharmacy,
  userHasPharmacy
}) => {
  // const { param } = param;
  const [medications, setMedications] = useState([]);
  const [tempDrugsPrices, setTempDrugsPrices] = useState([]);
  const [drugPrices, setDrugPrices] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [viewSavingOptions, setSavingOptions] = useState(false);
  const [selectMapValues, setSelectMapValues] = useState([]);
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);

  const [openedTabs, setOpenedTabs] = useState({});

  const disclaimer = useSelector((state) => state.copayPrices.disclaimer);

  // const [noOfApiCalls, setnoOfApiCalls] = useState(0); //medications length
  // const [medDrugData, setMedDrugData] = useState([]);
  // const [displayStyle, setDisplayStyle] = useState("");
  // const extendedDrugPrices = useSelector((state) => state.drugdetails.extendedDrugPrices);

  const tooltipRef = useRef(null);
  const mobileViewRef = useRef(null);

  const { matches: isTab } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 767 });
  var userDetail = useGetUser();


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && tooltipRef.current) {
        tooltipRef.current.hide();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleIsExpandable = (selectedMedicationDrug) => {
    setQuantity(selectedMedicationDrug?.selectedQty);
    setLabel(selectedMedicationDrug?.selectedLabel);
    setDosage(selectedMedicationDrug?.selectedDosage);
    setForm(selectedMedicationDrug?.selectedForm);
    var isExpandableTIndex = medicationInfo.findIndex(
      (x) => x?.isExpandable === true
    );
    if (isExpandableTIndex === -1) {
      var index = medicationInfo.findIndex(
        (x) => x?.selectedDrug?.id === selectedMedicationDrug?.selectedDrug?.id
      );
      if (index === -1) {
      } else {
        setIsVisibleEditModal(true);
        // selectedMedicationDrug.isExpandable = true;
        const obj = {
          InsuranceInfo: selectedMedicationDrug?.InsuranceInfo,
          amount_of_copay: selectedMedicationDrug?.amount_of_copay,
          bestPrice: selectedMedicationDrug?.bestPrice,
          chain: selectedMedicationDrug?.chain,
          costPlusDrugPrice: selectedMedicationDrug?.costPlusDrugPrice,
          description: selectedMedicationDrug?.description,
          dosageArr: selectedMedicationDrug?.dosageArr,
          estRetailPrice: selectedMedicationDrug?.estRetailPrice,
          formArr: selectedMedicationDrug?.formArr,
          hasInsurance: selectedMedicationDrug?.hasInsurance,
          hippoPrice: selectedMedicationDrug?.hippoPrice,
          isExpandable: true,
          logo: selectedMedicationDrug?.logo,
          medicationAPIRes: selectedMedicationDrug?.medicationAPIRes,
          patient_pay: selectedMedicationDrug?.patient_pay,
          pharmacyName: selectedMedicationDrug?.pharmacyName,
          rxSense: selectedMedicationDrug?.rxSence,
          rxSensePrice: selectedMedicationDrug?.rxSensePrice,
          selectedDosage: selectedMedicationDrug?.selectedDosage,
          selectedDrug: selectedMedicationDrug?.selectedDrug,
          selectedForm: selectedMedicationDrug?.selectedForm,
          selectedLabel: selectedMedicationDrug?.selectedLabel,
          selectedQty: selectedMedicationDrug?.selectedQty
        }
        const newState = [
          ...medicationInfo.slice(0, index),
          Object.assign({}, medicationInfo[index], obj),
          ...medicationInfo.slice(index + 1),
        ];
        setMedicationInfo(newState);
        //setLabel(medications[index]?.selectedLabel);
        //setForm(medications[index]?.selectedForm);
        //setDosage(medications[index]?.selectedDosage);
        //setQuantity(medications[index]?.selectedQty);
        //setType(medications[index]?.selectedDrug?.type);
      }
    } else {
      setIsVisibleEditModal(false);
      const obj = {
        InsuranceInfo: selectedMedicationDrug?.InsuranceInfo,
        amount_of_copay: selectedMedicationDrug?.amount_of_copay,
        bestPrice: selectedMedicationDrug?.bestPrice,
        chain: selectedMedicationDrug?.chain,
        costPlusDrugPrice: selectedMedicationDrug?.costPlusDrugPrice,
        description: selectedMedicationDrug?.description,
        dosageArr: selectedMedicationDrug?.dosageArr,
        estRetailPrice: selectedMedicationDrug?.estRetailPrice,
        formArr: selectedMedicationDrug?.formArr,
        hasInsurance: selectedMedicationDrug?.hasInsurance,
        hippoPrice: selectedMedicationDrug?.hippoPrice,
        isExpandable: false,
        logo: selectedMedicationDrug?.logo,
        medicationAPIRes: selectedMedicationDrug?.medicationAPIRes,
        patient_pay: selectedMedicationDrug?.patient_pay,
        pharmacyName: selectedMedicationDrug?.pharmacyName,
        rxSense: selectedMedicationDrug?.rxSence,
        rxSensePrice: selectedMedicationDrug?.rxSensePrice,
        selectedDosage: selectedMedicationDrug?.selectedDosage,
        selectedDrug: selectedMedicationDrug?.selectedDrug,
        selectedForm: selectedMedicationDrug?.selectedForm,
        selectedLabel: selectedMedicationDrug?.selectedLabel,
        selectedQty: selectedMedicationDrug?.selectedQty
      }
      const newState = [
        ...medicationInfo.slice(0, isExpandableTIndex),
        Object.assign({}, medicationInfo[isExpandableTIndex], obj),
        ...medicationInfo.slice(isExpandableTIndex + 1),
      ];
      setMedicationInfo(newState);
    }
  };


  const savePrescription = (obj, names, drugQuantities) => {
    let drugType = names?.filter((name) => name.name.toLowerCase() == obj?.Name.toLowerCase())?.[0]?.type;
    let request = {
      appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
      drugSeqNo: selectedDrug?.drugSeqNo,
      name: obj?.Name,
      // id: tempSelectedDrug?.id,
      type: drugType,
      search_name: obj?.Name,
      dosage: obj?.Dosage,
      form: obj?.Form,
      form_plural: obj?.Form,
      package_size: obj?.PackageQuantity,
      quantities: drugQuantities,
      default_quantity: obj?.Quantity,
      // default_dosage: tempSelectedDrug?.default_dosage,
      names: names.map((name) => {
        return name.name
      }),
      ndc: obj?.NDC,
      description: obj?.Description,
      // apiResponse: JSON.stringify(obj?.medicationAPIRes),
      insurance: patient?.bin && patient?.groupNumber && patient?.memberId ? {
        bin: patient?.bin,
        groupNumber: patient?.groupNumber,
        memberId: patient?.memberId,
        PCN: patient?.pcn
      } : null,

      // insurance: getInsuranceNumber(patientInsuranceInfo, insuranceObj),
      dob: patient?.dob,
      pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code
    };
    setAddOrUpdateMed(request);
    setIsVisibleEditModal(false);
  };

  const removeMedication = (index, drugSeqNo) => {
    sessionStorage.removeItem("selectMapValues");
    setRemoveDrug({ index: index, drugSeqNo: drugSeqNo });
  };

  const selectMap = (sub_element) => {
    setSelectMapValues(Object.assign({}, sub_element));
    sessionStorage.setItem("selectMapValues", JSON.stringify(sub_element));
  };

  const selectBestOffer = (hippo, rxSence, careCard, rxLess, type) => {
    const prices = [hippo, rxSence, careCard, rxLess].filter(price => price !== undefined && price !== null);

    if (prices.length === 0) {
      return;
    }

    const lowestPrice = Math.min(...prices);

    if (type && type == "comparison") return lowestPrice;
    return lowestPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function processInput(input) {
    if (typeof input === 'string' && input.startsWith('[') && input.endsWith(']')) {
      // Remove the brackets
      let trimmedInput = input.slice(1, -1);

      // Split the string by comma and trim each element
      let arrayOfStrings = trimmedInput.split(',').map(item => item.trim());

      return arrayOfStrings;
    } else {
      // Assume the input is already in the correct format
      return input;
    }
  }


  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <motion.section>
          <div className="">
            <div className="">
              <div className="">
                <form id="loginID">
                  <div className="row" id="cc-info-div">
                    <AnimatePresence>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        {medicationInfo
                          .slice()
                          .reverse()
                          ?.map((obj, index) => {
                            console.log({ infoObj: obj })
                            let names = processInput(obj.selectedDrug.names);
                            return (
                              <motion.div
                                className="swipe-list"
                                key={`${obj?.selectedDrug?.drugSeqNo}`}
                                // layout
                                initial={{
                                  y: -100,
                                  x: 0,
                                  opacity: 0,
                                }}
                                animate={{
                                  y: 0,
                                  x: 0,
                                  opacity: 1,
                                }}
                                exit={{
                                  opacity: 1,
                                  x: 200,
                                  // transition: { duration: 1 },
                                }}
                                transition={{ type: "tween" }}
                                whileHover={{
                                  scale: 1.02,
                                  transition: { duration: 0.2 },
                                }}
                                style={{ position: "relative", zIndex: 100 }}
                              >
                                <div className="item-group">
                                  <div className="row">
                                    <div
                                      className={
                                        deleteShowParam
                                          ? "col-10 col-sm-10 col-md-10 col-lg-10"
                                          : "col-12 col-sm-12 col-md-12 col-lg-12"
                                      }
                                    >
                                      <div
                                        className="medications_inner"
                                      >
                                        <div
                                          className="row"
                                          style={(obj?.selectedDrug?.manufacturerOffer && obj?.selectedDrug?.type == "Brand") || ((!obj?.selectedDrug?.manufacturerOffer || obj?.selectedDrug.type == "Generic") && obj?.costPlusDrugPrice >
                                            0 && !(obj?.estRetailPrice < obj?.costPlusDrugPrice)) || (obj?.selectedDrug?.connectiveRxOffer && obj?.selectedDrug?.connectiveRxOffer?.hasCard == "0") ? { borderBottom: "1px solid rgba(20, 48, 82, 0.08)", borderRadius: 8, marginInline: -16, boxShadow: "0px 3px 0px 0px rgba(20, 48, 82, 0.04)", paddingBottom: 6 } : {}}>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 autoMargin desktop-only"></div>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 autoMargin desktop-only">
                                            <div className="row">
                                              <div className="col-12 col-sm-12 col-md-9 col-lg-9 autoMargin">
                                                <div className="row">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p style={{ fontSize: 11, fontStyle: "italic", textAlign: "center", marginTop: -6, marginBottom: 2 }}>Click a box below to change quantity, strength or dosage form</p>
                                                  </div>

                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-3 col-lg-3 autoMargin webView" style={{ display: "flex", justifyContent: "end" }}>

                                              </div>

                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 autoMargin">
                                            <div className="row">
                                              {/* autoMargin */}
                                              <div
                                                className={
                                                  deleteShowParam
                                                    ? "col-12 col-sm-6 col-md-6 col-lg-6 "
                                                    : "col-6 col-sm-6 col-md-6 col-lg-6 "
                                                }
                                              >
                                                <div className="row">
                                                  <div className="col-4 col-sm-4 col-md-5 col-lg-3 autoMargin">
                                                    <div className="">
                                                      {obj?.selectedDrug?.form ==
                                                        "Tablet" && (
                                                          <div className="text-center">
                                                            <img
                                                              src={Tablet}
                                                              className="img-fluid "
                                                            />
                                                          </div>
                                                        )}
                                                      {obj?.selectedDrug?.form !=
                                                        "Tablet" && (
                                                          <div className="text-center">
                                                            <img
                                                              src={Liquid}
                                                              className="img-fluid "
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                  <div className="col-8 col-sm-8 col-md-7 col-lg-9 p-0 autoMargin ">
                                                    <div className="">
                                                      <h5
                                                        className="text-ellipsis"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={obj?.selectedDrug
                                                          ?.type === "Generic"
                                                          ? obj?.selectedDrug
                                                            ?.name
                                                          :
                                                          names[0] ===
                                                            obj?.selectedDrug
                                                              ?.name
                                                            ? names[1]
                                                            : names[0]}
                                                      >
                                                        {obj?.selectedDrug
                                                          ?.type === "Generic"
                                                          ? obj?.selectedDrug
                                                            ?.name
                                                          :
                                                          names[0] ===
                                                            obj?.selectedDrug
                                                              ?.name
                                                            ? names[1]
                                                            : names[0]}
                                                      </h5>
                                                      <h4
                                                        className="text-ellipsis "
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={
                                                          obj?.selectedDrug
                                                            ?.type === "Generic"
                                                            ? names[0] ===
                                                              obj?.selectedDrug
                                                                ?.name
                                                              ? names[1]
                                                              :
                                                              names[0]
                                                            : obj?.selectedDrug
                                                              ?.name
                                                        }
                                                      >
                                                        {obj?.selectedDrug
                                                          ?.type === "Generic"
                                                          ? names[0] ===
                                                            obj?.selectedDrug
                                                              ?.name
                                                            ? names[1]
                                                            : names[0]
                                                          : obj?.selectedDrug
                                                            ?.name}
                                                      </h4>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  deleteShowParam
                                                    ? "col-12 col-sm-6 col-md-6 col-lg-6 autoMargin amountFountSize"
                                                    : "col-6 col-sm-6 col-md-6 col-lg-6 autoMargin amountFountSize"
                                                }
                                              >
                                                <div className="medications_inner_right">
                                                  <div className="dlr-Sup ">
                                                    {(obj?.bestPrice || obj?.description == "with insurance") ? (
                                                      <>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                          <p style={{ fontSize: 11, lineHeight: 1.3, marginRight: 4 }}>
                                                            {obj?.description == "with insurance" ? "With Insurance" : "With RxLink Offer"}
                                                          </p>
                                                          {!(obj?.estRetailPrice == 0 || obj?.estRetailPrice <= obj.bestPrice || obj?.estRetailPrice == obj.bestPrice) && (
                                                            <>
                                                              <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                  <Tooltip id={`tooltip-${index}`}>
                                                                    <p >
                                                                      {!(obj?.estRetailPrice == 0 || obj?.estRetailPrice <= obj.bestPrice || obj?.estRetailPrice == obj.bestPrice) &&
                                                                        <>
                                                                          from <br />
                                                                          <span style={{ fontWeight: "bold" }}>${obj?.estRetailPrice?.toLocaleString(
                                                                            undefined,
                                                                            {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2,
                                                                            }
                                                                          )}</span>
                                                                          <span> retail price</span>
                                                                        </>}
                                                                      {(obj?.description == "with insurance" && obj?.bestPrice >= 0) >= selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice, "comparison") && <><br />
                                                                        <span style={{ fontWeight: "bold" }}>${(obj?.bestPrice)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </>}
                                                                      {obj?.description == "with insurance" && <><br />
                                                                        <span style={{ fontWeight: "bold" }}>${selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice)}</span>
                                                                        <span> with RxLink offer</span> </>}
                                                                      {obj?.description == "with insurance" && obj?.bestPrice >= 0 < selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice, "comparison") && <><br />
                                                                        <span style={{ fontWeight: "bold" }}>${(obj?.bestPrice)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </>}
                                                                      {(obj?.description != "with insurance") && obj.prismaDrugPrice != null && obj.prismaDrugPrice != undefined && <><br />
                                                                        <span style={{ fontWeight: "bold" }}>${parseFloat(obj?.prismaDrugPrice)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </>}
                                                                    </p>
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                {/* <a href="javascript:void(0)"> */}
                                                                <img tabIndex={0} src={InfoSvg} style={{ height: 14, width: 14, marginTop: -6, cursor: "pointer" }}
                                                                  alt="Offer Information"
                                                                />
                                                                {/* </a> */}
                                                              </OverlayTrigger>
                                                            </>
                                                          )}

                                                        </div>
                                                        <div className="medFlex">
                                                          {(obj?.estRetailPrice == 0 || obj?.estRetailPrice <= obj.bestPrice) ? <>
                                                          </> : <>
                                                            <h5 className="clr_a2a4a5 mb-0 padding3 clr_a2a4a5 normal">
                                                              $
                                                            </h5>
                                                            <h5 className="clr_a2a4a5 mb-0 padding3 strikeThroughText">
                                                              <div className="strikeThrough">
                                                                {obj?.estRetailPrice?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )}
                                                              </div>
                                                            </h5></>}
                                                          <label className="dlr-Sup-snd padding3">
                                                            <sup>
                                                              $&nbsp;
                                                            </sup>
                                                          </label>
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard != 1 &&
                                                            <h4 className="webView">
                                                              {obj?.bestPrice?.toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              ) ||
                                                                "N/A"}
                                                            </h4>}
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard != 1 && <h3 className="mobileView">
                                                            {obj?.bestPrice?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            ) ||
                                                              "N/A"}
                                                          </h3>}
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard == 1 &&
                                                            <h4 className="webView">
                                                              {parseFloat(obj?.selectedDrug?.connectiveRxOffer?.price)?.toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              ) ||
                                                                "N/A"}
                                                            </h4>}
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard == 1 && <h3 className="mobileView">
                                                            {parseFloat(obj?.selectedDrug?.connectiveRxOffer?.price)?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            ) ||
                                                              "N/A"}
                                                          </h3>}
                                                        </div>
                                                      </>

                                                    ) : (<>Not Available</>)}

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 autoMargin ">
                                            <div className="row">
                                              <div className="col-12 col-sm-12 col-md-9 col-lg-9 autoMargin">
                                                <div className="row">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p style={{ fontSize: 11, fontStyle: "italic", textAlign: "center" }} className="mobile-only">
                                                      Click a box below to change quantity, strength or dosage form
                                                    </p>
                                                    <div
                                                      className=" text-center medications_inner peiceUpdate pointer"
                                                      onClick={() => {
                                                        setIsVisibleEditModal(true);
                                                        setSelectedDrug(obj.selectedDrug);
                                                        // handleIsExpandable(obj)
                                                      }}
                                                    >
                                                      <a className="text-center m-0 pointer">
                                                        {obj?.selectedDosage}
                                                        {obj?.selectedDosage &&
                                                          ","}{" "}
                                                        {obj?.selectedQty}{" "}
                                                        {obj?.selectedForm}
                                                      </a>
                                                    </div>
                                                  </div>
                                                  {/* LOGO CODE HERE */}

                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-3 col-lg-3 autoMargin webView">
                                                <div className="text-end">
                                                  <img
                                                    src={trash}
                                                    className=" pointer"
                                                    onClick={() =>
                                                      removeMedication(
                                                        medicationInfo?.length -
                                                        1 -
                                                        index,
                                                        obj?.selectedDrug
                                                          ?.drugSeqNo
                                                      )
                                                    }
                                                  ></img>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {obj?.selectedDrug?.manufacturerOffer && obj?.selectedDrug?.type == "Brand" &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit" }}
                                            >
                                              {obj?.selectedDrug?.manufacturerOffer.offerDescription + " "}

                                              <a
                                                href={obj?.selectedDrug?.manufacturerOffer.offerLink}
                                                target="_blank"
                                                style={{ fontSize: 11, paddingLeft: 4 }}
                                                onClick={(e) => {
                                                  e.preventDefault();

                                                  let drugSeqNo = obj?.selectedDrug?.drugSeqNo;
                                                  if (openedTabs[drugSeqNo] && !openedTabs[drugSeqNo].closed) {
                                                    openedTabs[drugSeqNo].focus();
                                                  }
                                                  else {
                                                    let reference = window.open(obj?.selectedDrug?.manufacturerOffer.offerLink, "_blank");
                                                    setOpenedTabs(prevState => ({ ...prevState, [drugSeqNo]: reference }));
                                                    httpClientConnective.post("manufactureTracking", {
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      fromUi: 1,
                                                      crxBrandSeqNo: obj.selectedDrug.manufacturerOffer.crxBrandSeqNo,
                                                      drugSeqNo: obj.selectedDrug.drugSeqNo,
                                                      pharmacyNpi: ""
                                                      //valParam.npi
                                                    })
                                                      .then((res) => {
                                                      }).catch((err) => {
                                                        console.log({ err })
                                                      })
                                                  }

                                                }}
                                              >
                                                Learn More
                                              </a>
                                            </div>
                                          </div>
                                        }
                                        {(!obj?.selectedDrug?.manufacturerOffer || obj?.selectedDrug.type == "Generic") && obj?.costPlusDrugPrice >
                                          0 && !(obj?.estRetailPrice < obj?.costPlusDrugPrice) &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit", display: "inline-flex", alignItems: "center" }}
                                            >
                                              <img
                                                src={CPDImg}
                                                className={"CPDIMG"}
                                                alt="Mark Cuban Cost Plus Drug Company"
                                              ></img>
                                              {!isMobile && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: isMobile ? "0.6rem" : "1.2rem", float: isMobile ? "right" : "none" }}>
                                                {obj?.estRetailPrice > obj?.costPlusDrugPrice && <span style={{ color: "rgba(0,0,0,0.35)", }} className="strikeThrough">
                                                  ${obj?.estRetailPrice?.toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </span>}
                                                <span style={{ paddingLeft: 4, fontWeight: 600, color: "rgba(0,0,0,0.7)" }}>
                                                  ${obj?.costPlusDrugPrice?.toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  ) ||
                                                    "N/A"}
                                                </span>
                                              </div>}
                                              <div style={{ display: "inline-block", paddingLeft: isMobile ? "0.6rem" : "1.2rem" }}>
                                                <OverlayTrigger
                                                  delay={{ hide: 100, show: 100 }}
                                                  trigger="hover"
                                                  ref={tooltipRef}
                                                  overlay={(props) => (
                                                    <Popover {...props}>

                                                      <div style={{ padding: 8, textAlign: "center" }}>Click here to sign up for Mark Cuban CostPlus Drug Company</div>
                                                    </Popover>
                                                  )}
                                                  placement="auto"
                                                >
                                                  <NewTabRedirector
                                                    type="cpd"
                                                    apiEndpoint={(payload) => httpClientConnective.post("costPlusDrugTracking", payload)}
                                                    apiPayload={{
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      ndc: obj.selectedDrug.ndc,
                                                      rxLinkBestPrice: "$" + obj.bestPrice,
                                                      preferredPharmacyPrice: "$" + obj.bestPrice,
                                                      costPlusDrugPrice: "$" + obj.costPlusDrugPrice
                                                    }}
                                                    tabKey={obj?.selectedDrug?.drugSeqNo}
                                                    label="Join and save"
                                                    style={{ fontSize: 11 }}
                                                    loadingMessage="Please wait while we redirect you to CostPlus Drug..."
                                                    errorMessage="Something went wrong while loading CostPlus Drug!"
                                                    onError={(err) => console.log("Failed to redirect:", err)}
                                                  />
                                                </OverlayTrigger>
                                              </div>
                                              {isMobile && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: "0.5rem", paddingRight: "0.5rem", float: isMobile ? "right" : "none" }}>
                                                {!(obj?.estRetailPrice <= obj?.costPlusDrugPrice) && <span style={{ color: "rgba(0,0,0,0.35)", }} className="strikeThrough">${obj?.estRetailPrice?.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}</span>}
                                                <span style={{ paddingLeft: 4, fontWeight: 600, color: "rgba(0,0,0,0.7)" }}>${obj?.costPlusDrugPrice?.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                ) ||
                                                  "N/A"}</span>
                                              </div>}
                                            </div>
                                          </div>
                                        }
                                        {
                                          (obj?.selectedDrug?.connectiveRxOffer && obj?.selectedDrug?.connectiveRxOffer?.partner !== "goodRx" && obj?.selectedDrug?.connectiveRxOffer?.hasCard == "0" && obj?.selectedDrug?.connectiveRxOffer?.hasImageUrl == "1") &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit", display: "inline-flex", alignItems: "center" }}
                                            >
                                              <LogoComponent drug={obj?.selectedDrug} />
                                              {true && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: 18 }}>
                                                <span style={{ fontWeight: 500, color: "rgba(0,0,0,0.7)" }}>
                                                  {obj?.selectedDrug?.connectiveRxOffer?.offerDesc + " "}
                                                  <NewTabRedirector
                                                    type="connective"
                                                    apiEndpoint={(payload) => httpClientConnective.post("generateConnectiveRx", payload)}
                                                    apiPayload={{
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      connectiveRxRequest: [
                                                        {
                                                          appUserSeqNo: userDetail?.appUserSeqNo,
                                                          drugNdc: obj?.selectedDrug?.connectiveRxOffer?.drugNdc,
                                                          crxNetPriceSubSeqNo: obj?.selectedDrug?.connectiveRxOffer?.crxNetPriceSubSeqNo,
                                                          dob: patient?.dob,
                                                          gender: patient?.gender,
                                                          patientSeqNo: patient?.patientSeqNo,
                                                          pharmacySeqNo: userPharmacy?.userPharmacySeqNo || "1213",
                                                          pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code,
                                                          zipcode: userHasPharmacy ? userPharmacy.zipcode : ownPosition?.responseZIP?.postal,
                                                          campaignEndDate: obj?.selectedDrug?.connectiveRxOffer?.campaignEndDate,
                                                          partner: obj?.selectedDrug?.connectiveRxOffer?.partner,
                                                          npi: userDetail?.npi
                                                        },
                                                      ]
                                                    }}
                                                    tabKey={obj?.selectedDrug?.drugSeqNo}
                                                    label="Click Here"
                                                    style={{ fontSize: 11 }}
                                                    loadingMessage="Please wait while we redirect you to saving offer..."
                                                    errorMessage="Something went wrong while loading saving offer!"
                                                    onError={(err) => console.log("Failed to redirect:", err)}
                                                  />
                                                </span>
                                              </div>}
                                            </div>
                                          </div>
                                        }
                                        {
                                          (obj?.selectedDrug?.connectiveRxOffer && obj?.selectedDrug?.connectiveRxOffer?.partner === "goodRx" && obj?.selectedDrug?.connectiveRxOffer?.hasCard == "0" && obj?.selectedDrug?.connectiveRxOffer?.hasImageUrl == "1" && (normalizeCompanyName(pharmacyState?.pharmacyName) === "cvs" || pharmacyState?.npi == "1326382888" || pharmacyState?.npi == "1497097968")) &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit", display: "inline-flex", alignItems: "center" }}
                                            >
                                              {/* <LogoComponent drug={obj?.selectedDrug} /> */}
                                              {true && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: 18 }}>
                                                <span style={{ fontWeight: 500, color: "rgba(0,0,0,0.7)" }}>
                                                  {obj?.selectedDrug?.connectiveRxOffer?.offerDesc + " "}
                                                  <NewTabRedirector
                                                    type="connective"
                                                    apiEndpoint={(payload) => httpClientConnective.post("generateConnectiveRx", payload)}
                                                    apiPayload={{
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      connectiveRxRequest: [
                                                        {
                                                          appUserSeqNo: userDetail?.appUserSeqNo,
                                                          drugNdc: obj?.selectedDrug?.connectiveRxOffer?.drugNdc,
                                                          crxNetPriceSubSeqNo: obj?.selectedDrug?.connectiveRxOffer?.crxNetPriceSubSeqNo,
                                                          dob: patient?.dob,
                                                          gender: patient?.gender,
                                                          patientSeqNo: patient?.patientSeqNo,
                                                          pharmacySeqNo: userPharmacy?.userPharmacySeqNo || "1213",
                                                          pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code,
                                                          zipcode: userHasPharmacy ? userPharmacy.zipcode : ownPosition?.responseZIP?.postal,
                                                          campaignEndDate: obj?.selectedDrug?.connectiveRxOffer?.campaignEndDate,
                                                          partner: obj?.selectedDrug?.connectiveRxOffer?.partner,
                                                          npi: userDetail?.npi
                                                        },
                                                      ]
                                                    }}
                                                    tabKey={obj?.selectedDrug?.drugSeqNo}
                                                    label="Click Here"
                                                    style={{ fontSize: 11 }}
                                                    loadingMessage="Please wait while we redirect you to saving offer..."
                                                    errorMessage="Something went wrong while loading saving offer!"
                                                    onError={(err) => console.log("Failed to redirect:", err)}
                                                  />
                                                </span>
                                              </div>}
                                            </div>
                                          </div>
                                        }
                                        <div className="row">
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        deleteShowParam
                                          ? "col-2 col-sm-2 col-md-2 col-lg-2 marginAuto"
                                          : "col-12 col-sm-12 col-md-12 col-lg-12"
                                      }
                                    >
                                      {deleteShowParam && (
                                        <div className="box-action">
                                          <i
                                            class="fa-regular fa-trash-can pointer"
                                            onClick={() =>
                                              removeMedication(
                                                medications?.length - 1 - index,
                                                obj?.selectedDrug?.drugSeqNo
                                              )
                                            }></i>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </motion.div>
                            );
                          })}
                        {/* {medicationInfo.length > 0 && <div style={{ position: "relative", top: 6 }}>
                          <p style={{ fontSize: 13 }}>
                            Savings based on all data currently available to RxLink, including your insurance and co-pay. Always compare the RxLink discounted price to your insurance co-pay; your price with
                            insurance should never be more than the RxLink price, but may be lower.
                          </p>
                        </div>} */}
                        {
                          disclaimer.length > 0 &&
                          <div style={{ position: "relative", top: 6 }}>
                            <p style={{ fontSize: 13 }}>
                              {/* {disclaimer?.[0]} */}
                              <StringToHTML htmlString={disclaimer?.[0]?.text} />
                            </p>
                          </div>
                        }
                        {viewSavingOptions && (
                          <div
                            className=""
                            id="listingContent"
                            initial={{ y: -300 }}
                            animate={{ y: 0 }}
                            transition={{ type: "tween", duration: 2.2 }}
                          // style={{position: "relative", zIndex: -1}}
                          >
                            <div className="mb-4">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="price_comparison_home_4">
                                    <AnimatePresence>
                                      <div className="border-top">
                                        {bestPackage?.map(
                                          (sub_element) => (
                                            <motion.div
                                              className="row"
                                              key={sub_element}
                                              layout
                                              initial={{
                                                y: -150,
                                                // x: 0,
                                                opacity: 0,
                                                // scale: 0.8
                                              }}
                                              animate={{
                                                y: 0,
                                                // x: 0,
                                                opacity: 1,
                                                // scale:1
                                              }}
                                              exit={{
                                                opacity: 0,
                                              }}
                                              transition={{
                                                type: "tween",
                                                delay: 0.3,
                                              }}
                                            >
                                              {sub_element?.chain !==
                                                "cost_plus_drug" && (
                                                  <>
                                                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 flex justify-content">
                                                      <div className="small_medication_logo img-size">
                                                        <img
                                                          src={
                                                            searchPharmacyLogo(sub_element?.pharmacyName, sub_element?.logo
                                                              ?.small)
                                                          }
                                                          className="img-fluid"
                                                        />
                                                      </div>
                                                      <h6>
                                                        {
                                                          sub_element?.pharmacyName
                                                        }
                                                      </h6>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-3 col-lg-3">
                                                      <div className="text-center">
                                                        <p>
                                                          <sup>$&nbsp;</sup>
                                                          <s>
                                                            {sub_element?.estRetailPrice?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}
                                                          </s>
                                                        </p>
                                                        <span>
                                                          est. cash price
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-3 col-lg-3">
                                                      <div className="text-center">
                                                        <p>
                                                          <sup>$&nbsp;</sup>
                                                          {sub_element?.bestPrice?.toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </p>
                                                        <span>
                                                          {sub_element?.description}
                                                          {/* with RxLink discount */}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                                                      <MotionButton>
                                                        <button
                                                          className="btn_success"
                                                          onClick={() =>
                                                            selectMap(sub_element)
                                                          }
                                                        >
                                                          Select
                                                        </button>
                                                      </MotionButton>
                                                    </div>

                                                    <hr className="m-0"></hr>
                                                  </>
                                                )}
                                            </motion.div>
                                          )
                                        )}
                                      </div>
                                    </AnimatePresence>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </AnimatePresence>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.section>
      )

      }
      <EditMedicationModal
        isVisibleEditModal={isVisibleEditModal}
        setIsVisibleEditModal={setIsVisibleEditModal}
        selectedDrug={selectedDrug}
        setSelectedDrug={setSelectedDrug}
        savePrescription={savePrescription}
      />
    </>
  );
};
